.footer {
  padding: 50px;
}

.link {
  text-decoration: none;
  color: #33dfff;
  // color: #09d3ac;
  // color: #428bca;
}
