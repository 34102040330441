@import "./design-patterns/constants.scss";

.container {
  text-align: center;
  font-family: $font;
}

.logo {
  height: 40vmin;
}

.header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.link {
  color: #09d3ac;
}
