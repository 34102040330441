@import "../../design-patterns/constants.scss";

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}

.presentation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 0px;
  background-color: #f5f5f5;
  width: 100%;
}

.selfie {
  border-radius: 50%;
  margin-bottom: 30px;
}

.name {
  font-size: 56px;
  margin-bottom: 30px;
}

.links {
  display: flex;
  flex-direction: row;
  align-self: center;
}

.socialIcon {
  font-size: 30px;
  margin: 0 5px;
  text-decoration: none;
}

.github {
  composes: socialIcon;
  color: #000;
}

.linkedin {
  composes: socialIcon;
  color: #4393B9;
}

.email {
  composes: socialIcon;
  color: #8b0000;
}

.separator {
  width: 100%;
  border: 1px solid rgba(0,0,0,0.1);
  margin: 20px 0;
}

.about {
  max-width: $breakpoint;
  text-align: left;
  margin: 100px 0;

  @media(max-width: $breakpoint) {
    padding: 0 20px;
  }
}

.title {
  font-size: 2rem;
  margin: 10px 0;
  text-align: left;
}

.copy {
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 10px;
}

.skillset {
  max-width: $breakpoint;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;

  @media(max-width: $breakpoint) {
    align-self: flex-start;
    padding: 0 20px;
  }
}

.skillColumns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media(max-width: $breakpoint) {
    flex-direction: column;
  }
}

.column {
  width: 20%;

  @media(max-width: $breakpoint) {
    width: 100%;
  }
}

.skillTitle {
  text-align: left;
  font-size: 1.5rem;
}

.skillList {
  padding-inline-start: 18px;
  text-align: left;
}
